import styled from "styled-components";

export const MainSpace = styled.div`
	width: 100%;
`;

export const FormSpace = styled.div`
	width: 100%;
`;

export const LogoSpace = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 5rem;
`;

export const Img = styled.img`
	width: 50px;
	height: 50px;
`;
